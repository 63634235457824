<template>
    <div>
        <h3>To cite MCOT package:</h3>
        <p>Levitsky V, Zemlyanskaya E, Oshchepkov D, Podkolodnaya O, Ignatieva E,
    Grosse I, Mironova V, Merkulova T. A single ChIP-seq dataset is sufficient
    for comprehensive analysis of motifs co-occurrence with MCOT package.
    Nucleic Acids Res. 2019, 47(21):e139. <a href="https://academic.oup.com/nar/article-lookup/doi/10.1093/nar/gkz800">doi:10.1093/nar/gkz800</a></p>

      <p>
              Levitsky V, Oshchepkov D, Zemlyanskaya E, Merkulova T. Asymmetric
conservation within pairs of co-occurred motifs mediates weak direct
binding of transcription factors in ChIP-seq data. Int J Mol Sci. 2020,
              21(17):6023. <a href="https://www.mdpi.com/1422-0067/21/17/6023">doi:10.3390/ijms21176023</a>
            </p>


        <h3>To cite Web MCOT application:</h3>
        <p>Levitsky V, Mukhin A, Oshchepkov D, Zemlyanskaya E, Lashin S. MCOT web
tool for motifs co-occurrence search in ChIP-seq data (under review)
</p>

    </div>

</template>

<script>
    export default {
        name: "Citing"
    }
</script>

<style scoped>

</style>